<template>
  <div>
    <b-modal size="md" title="이미지 상세보기" hide-footer v-model="modal.image" v-if="item" @hide="modalHide">
      <b-carousel
          id="carousel-1"
          controls
          :interval="0"
      >
        <b-carousel-slide
            v-for="(img, idx) in item.img_urls.map(e => ({url: e, type: 'final'})).concat(item.temp_img_urls.map(e => ({url: e, type: 'temp'})))"
            :key="idx"
        >
          <template #img>
            <b-img
              fluid
              :src="img.url"
              alt="goods images">
              @click=""
            </b-img>
            <div class="carousel-extra-content">
              <template v-if="img.url === item.ep_img_url">
                <b-badge variant="danger">EP 썸네일</b-badge><br/>
              </template>
              <template v-if="img.type === 'final'">
                <b-badge class="pointer" @click="copyText(img.url)" variant="primary"><i class="fa fa-copy"></i> 최종이미지</b-badge><br/>
              </template>
              <template v-else>
                <b-badge class="pointer" @click="copyText(img.url)" variant="secondary"><i class="fa fa-copy"></i> 임시이미지</b-badge><br/>
              </template>
            </div>
          </template>
        </b-carousel-slide>
      </b-carousel>
    </b-modal>
  </div>
</template>

<script>
import * as utils from '@/shared/utils'
import {down} from '@/shared/impexp'
import {Types} from "mongoose";

export default {
  name: "ImageModal",
  props: ['modal'],
  data() {
    return {
      utils,
      preset: {
        id: '',
        name: '',
        img_urls: [],
        images: null,
        temp_img_urls: [],
        temp_images: null,
        designer_sku: '',
        ep_image: null,
        ep_img_url: '',
      },
      item: null,
    };
  },
  async created() {

  },
  methods: {
    show(item) {
      let newItem = {};
      for (const k of Object.keys(this.preset)) {
        newItem[k] = item && item[k] || this.preset[k];
      }

      this.item = this.$utils.clone(newItem);
      console.log(this.item);

      this.modal.image = true;
    },
    async modalHide(event) {
      console.log(event);
      this.item = null;
    },
    copyText(e) {
      let res = this.$utils.copyToClipboard(e);
      if (res) this.$alertTop(`복사되었습니다`);
    },
  }
}
</script>

<style scoped>
.carousel-extra-content {
  position: absolute;
  left: 1.5%;
  top: 1%;
  z-index: 20;
}
</style>
